<template>
    <centered-layout>
        <div class="text-center">
            <img
                :src="imageSrc"
                width="112px"
                alt="Snowflake"
            >
        </div>
        <div class="text-center">
            <h5
                class="fw-light"
                v-html="title"
            />
        </div>
        <div class="d-grid">
            <a
                class="btn btn-secondary btn-block mb-2"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.experian.com/ncaconline/removefreeze"
            > Unfreeze Report </a>
        </div>
        <div class="d-grid">
            <button
                class="btn btn-primary btn-block mb-2"
                @click="tryAgain"
            >
                Try Again
            </button>
        </div>
    </centered-layout>
</template>

<script>
    import { getNextRoute } from '@/flow/flowController'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { startCase, toLower } from 'lodash'
    import CenteredLayout from '@/layouts/Centered'

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        props: {
            isCoApplicant: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                imageSrc: require('../../assets/images/global/snowflake.svg'),
            }
        },
        mounted() {
            this.$logEvent('view_experian_frozen')
        },
        computed: {
            title() {
                if (this.isCoApplicant) {
                    // Co-applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)))
                    return `It looks like ${firstName}’s credit report is frozen – nice security! Please unfreeze temporarily and try again.`
                } else if (appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                    // Primary applicant with a co-applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(sessionStorageKey.firstName)))
                    return `It looks like ${firstName}’s credit report is frozen – nice security! Please unfreeze temporarily and try again.`
                } else {
                    // Individual applicant
                    return 'It looks like your credit report is frozen – nice security! Please unfreeze temporarily and try again.'
                }
            },
        },
        methods: {
            tryAgain: async function () {
                const nextRoute = getNextRoute(this.$router)
                this.$logEvent('click_button_experian_frozen_try_again', {
                    nextRoute,
                })
                await this.$router.push(nextRoute)
            },
        },
    }
</script>
